

<template id="ach-confirmation">
  <div>
<br>
   <v-card>
    <v-img  v-if="this.$route.params.success !== 'false'" src="@/assets/images/license_plate.png" class="">

        <v-row class="gotham-font px-4 mt-md-4 mt-lg-16" style="height: 100%; padding-top: 40px">
            <v-col>
              <v-card-title class="text-h5 font-weight-bold mainBlack--text justify-center">PAYMENT REQUEST ACCEPTED - {{ this.$route.params.message  }}</v-card-title>
              <v-card-text class="text-h6 text-center mainBlack--text">Payment request was submitted successfully and is <b>PENDING</b> final approval from your financial institution. 

 </v-card-text>
                <v-card-text class="text-h8 text-center mainBlack--text"> Please do not click SUBMIT repeatedly as this will result in duplicate payments.
                <br>Upon final verification that funds have been received, (2 - 5 business days from payment submission) 
                  The Title Girl will submit your documents and payment for processing at the county. <br>In the event payment is rejected at your financial institution, you will receive an error message with further instructions.</v-card-text>

            </v-col>
          </v-row>


    </v-img>

    <v-img  v-if="this.$route.params.success !== 'true'" src="@/assets/images/license_plate.png" class="">

    <v-row class="gotham-font px-4 mt-md-4 mt-lg-16" style="height: 100%; padding-top: 40px">
            <v-col>
              <v-card-title class="text-h5 font-weight-bold mainRed--text justify-center">YOUR PAYMENT WAS NOT TRANSMITTED</v-card-title>
              <v-card-text class="text-h6 text-center mainBlack--text">{{ this.$route.params.message  }} Please re-submit payment by clicking          
                 <router-link :to="{ name: 'app.registration.paymentDetails' }">here</router-link>.
              </v-card-text>


                <br> <br><v-card-text class="text-h8 text-center mainBlack--text"> If you continue to have issues, please email register@thetitlegirl.com or call us at (214) 997-6005. Please indicate the error message you are receiving. Thank you.</v-card-text>

            </v-col>
          </v-row>
    
    </v-img>  

    </v-card>

  </div>

</template>


<script>
export default {
  components: {},
  created() {
    const success = this.$route.params.success;
    const message = this.$route.params.message;
  },
  props: {
    text: {
      type: String,
      default: 'NEW',
      required: false,
    },
  },
  data: () => ({
    achConfirmationModal: false,
 //   success: '',
    message: ''
  }),
  computed: {
  },
  watch: {},
  methods: {},
};
</script>

<style scoped></style>
